<template src="./template.html"></template>

<script>
export default {
  name: "ServiceDetail",
  data() {
    return {
      steps: [
        {
          title: "製成規劃",
          img: "/img/service_steps/1.svg"
        },
        {
          title: "模具開發",
          img: "/img/service_steps/2.svg"
        },
        {
          title: "塑膠射出",
          img: "/img/service_steps/3.svg"
        },
        {
          title: "表面加工",
          img: "/img/service_steps/4.svg"
        },
        {
          title: "組立包裝",
          img: "/img/service_steps/5.svg"
        },
        {
          title: "出貨",
          img: "/img/service_steps/6.svg"
        },
      ]
    }
  },
  methods: {
    delay(index) {
      if (window.innerWidth > 601) {
        let val = (index + 1) % 3
        if (val == 0) {
          val = 3
        }
        return val * 200
      } else {
        return 200
      }
    }
  }

}
</script>