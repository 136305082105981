<template>
  <div id="app">
    <Loading :loading="loading" />
    <Dialog :dialog="dialog" v-on:toggle-dialog="ToggleDialog" />
    <MainNav />
    <TopCarousel />
    <About />
    <Service />
    <ServicesDetail />
    <TimeLine />
    <Products />
    <Contact
      v-on:toggle-loading="ToggleLoading"
      v-on:toggle-dialog="ToggleDialog"
    />
    <footer>
      <p>
        Copyright © 2014-2021 達信塑膠著作權所有，並保留一切權利。 退/換貨政策
        ，網頁設計：<a href="https://www.yongxin-design.com"
          >泳欣事業有限公司</a
        >
      </p>
    </footer>
  </div>
</template>

<script>
import MainNav from './components/MainNav/index.vue'
import TopCarousel from './components/TopCarousel/index.vue'
import About from './components/About/index.vue'
import Service from './components/Service/index.vue'
import ServicesDetail from './components/ServicesDetail/index.vue'
import TimeLine from './components/TimeLine/index.vue'
import Products from './components/Products/index.vue'
import Contact from './components/Contact/index.vue'
import Loading from './components/Loading/index.vue'
import Dialog from './components/YxDialog/index.vue'


export default {
  name: 'App',
  components: {
    MainNav,
    TopCarousel,
    About,
    Service,
    ServicesDetail,
    TimeLine,
    Products,
    Contact,
    Loading,
    Dialog
  },
  data() {
    return {
      loading: false,
      dialog: {
        status: false,
        msg: ""
      }
    }
  },
  methods: {
    ToggleLoading(val) {
      this.loading = val
    },
    ToggleDialog(data) {
      this.dialog.status = data[0]
      this.dialog.msg = data[1]
    }
  },
  metaInfo() {
    return {
      title: '達信塑膠有限公司 | DaShin Plastics',
      meta: [
        {
          property: 'og:description',
          content:
            '達信塑膠創立於1986年，致力於發展模具設計開發，專研塑膠鋼模射出成型技術，對於產品種類範圍不設限，多方面涉獵，迄今已累積30多年經驗。對於客戶以「高配合度」建立了良好的口碑，客戶的需求，就是我們努力的方向。 我們可以從產品設計規劃、塑膠射出成型、產品的二次加工、成品裝箱包裝，以達到生產一條龍的服務，讓客戶能夠在最快速的時間上拿到品質最好的產品，避免產生足球式的困擾。 ',
          vmid: 'og:description',
        },
        {
          property: 'description',
          content:
            '達信塑膠創立於1986年，致力於發展模具設計開發，專研塑膠鋼模射出成型技術，對於產品種類範圍不設限，多方面涉獵，迄今已累積30多年經驗。對於客戶以「高配合度」建立了良好的口碑，客戶的需求，就是我們努力的方向。 我們可以從產品設計規劃、塑膠射出成型、產品的二次加工、成品裝箱包裝，以達到生產一條龍的服務，讓客戶能夠在最快速的時間上拿到品質最好的產品，避免產生足球式的困擾。 ',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: window.location.origin + '/img/carousel/banner_1.png',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '達信塑膠有限公司 | DaShin Plastics',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '達信塑膠有限公司 | DaShin Plastics',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '達信塑膠有限公司 | DaShin Plastics',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh-TW',
          vmid: 'og:locale',
        },
      ],
    }
  },
}
</script>


<style lang="scss" src="@/assets/css/app.scss"></style>
