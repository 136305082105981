import Vue from 'vue'
import App from './App.vue'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.config.productionTip = false
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import AOS from "aos"
import "aos/dist/aos.css"

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(AOS.init({
  disable: false,
  startEvent: 'load',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120,
  delay: 0,
  duration: 400,
  easing: 'ease-in-out-quad',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom'
}))

import DataValid from "./common/data_valid";
Vue.mixin(DataValid)

Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )
new Vue({
  store,
  render: h => h(App)
}).$mount('#app')