<template src="./template.html"></template>

<script>
export default {
  name: "MainNav",
  data() {
    return {
      menu_open: false,
    }
  },
  methods: {
    ScrollTo(val) {
      this.menu_open = false
      let el_top = document.getElementById(val).offsetTop;
      window.scrollTo({
        top: el_top - 100,
        left: 0,
        behavior: "smooth"
      })
    }
  }
}
</script>