<template src="./template.html"></template>

<script>
export default {
  name: "Dialog",
  props: {
    dialog: {
      type: Object
    }
  },
  methods: {
    CloseDialog() {
      this.$emit("toggle-dialog", [false, ""])
    }
  }
}
</script>