<template src="./template.html"></template>

<script>
export default {
  name: "Service",
  data() {
    return {
      services: [
        {
          title: "模具開發",
          content: "製作塑膠產品流程中，良好的模具設計為首要關鍵，模具將影響生產的品質和良率，我們掌握最專業的模具設計和製造技術，提供客戶完整的生產規劃，並降低風險、提升射出成型之效率，使用模流分析系統，將預先準確地的找出產品缺失。",
          img: "/img/services/3d-modeling.png",
          open: true,
          list: [
            {
              title: "模具開發",
              content: "製作塑膠產品流程中，良好的模具設計為首要關鍵，模具將影響生產的品質和良率，我們掌握最專業的模具設計和製造技術，提供客戶完整的生產規劃，並降低風險、提升射出成型之效率，使用模流分析系統，將預先準確地的找出產品缺失。",
            }
          ]
        },
        {
          title: "塑膠射出",
          content: "達信塑膠專精於熱塑性塑膠射出成型，可使用的成型材料 例如: ABS、PP、PE、TPR、PC、HIPS、PA、PBT、PMMA、POM、PS、Nylon 等等。",
          img: "/img/services/factory-machine.png",
          open: false,
          list: [
            {
              title: "單色成型",
              content: "達信塑膠專精於熱塑性塑膠射出成型，可使用的成型材料 例如: ABS、PP、PE、TPR、PC、HIPS、PA、PBT、PMMA、POM、PS、Nylon 等等。",
            },
            {
              title: "雙色成型",
              content: "跟隨著射出成型技術不斷的發展，現今生產科技可以使用兩種不同的熱可塑性塑膠生產產品。在機器的一個成型週期內，使用兩種不同的塑膠粒和兩種不同顏色的原料生產簡單或複雜的成品的能力即為雙射成型。 雙射成型材料可以是不同的聚合物和或軟硬度不同的結合。達信塑膠通過不斷改進以及精進雙射成型技術，已可將複雜功能的料件有效率的大規模量產。",
            },
            {
              title: "埋射成型",
              content: "埋射成型也有叫插件成型，其成型方式就是在注塑成型的時候，在模具內放入一個五金件或零件然後合模射出， 也就是在五金件或零件上包覆一層塑膠。達信塑膠已有許多成功的合作案例，累積相當多經驗在於埋射成型的領域。",
            }
          ]
        },
        {
          title: "產品加工",
          content: "整合性代工-多樣性製程整合服務(包含二次加工-燙金、印刷、雷射雕刻...等)",
          img: "/img/services/manufacture.png",
          open: false,
          list: [
            {
              title: "產品加工",
              content: "整合性代工-多樣性製程整合服務(包含二次加工-燙金、印刷、雷射雕刻...等)",
            }
          ]
        }
      ],
      swiperOption: {
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  filters: {
    text_limit(val) {
      return (val.slice(0, 40) + "...");
    }
  },
  methods: {
    ActiveService(active_item) {
      this.services.forEach(item => {
        if (item == active_item) {
          item.open = true
        }
        else {
          item.open = false
        }
      })

      let el_top = document.getElementsByClassName("service_detail")[0].offsetTop;
      window.scrollTo({
        top: el_top - 200,
        left: 0,
        behavior: "smooth"
      })

    }
  },
  computed: {
    service() {
      return this.services.filter(item => {
        return item.open == true
      })[0]
    }
  }
}
</script>