<template src="./template.html"></template>

<script>
export default {
  name: "Products",
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
      },
      min_swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
      },
      products: [
        {
          title: "各種機械配件",
          image: "/img/products/1.png"
        },
        {
          title: "化妝品",
          image: "/img/products/2.png"
        },
        {
          title: "化妝品",
          image: "/img/products/3.png"
        },
        {
          title: "手工具",
          image: "/img/products/4.png"
        },
        {
          title: "民生用品",
          image: "/img/products/5.png"
        },
        {
          title: "民生用品",
          image: "/img/products/6.png"
        },
        {
          title: "民生用品",
          image: "/img/products/7.png"
        },
      ]
    }
  },
  methods: {
    ToggleSwiper(val) {
      if (val == -1) {
        this.$refs.big_prev.click()
        this.$refs.min_prev.click()
      }
      else if (val == 1) {
        this.$refs.big_next.click()
        this.$refs.min_next.click()
      }
    }
  }
}
</script>