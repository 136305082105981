<template src="./template.html"></template>
<script>
export default {
  name: "TopCarousel",
  data() {
    return {
      background: [
        "/img/carousel/banner_1.png",
        "/img/carousel/banner_2.png",
      ],
      swiperOption: {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  }
}
</script>