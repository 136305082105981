<template src="./template.html"></template>

<script>
export default {
  name: "Loading",
  props: {
    loading: {
      type: Boolean
    }
  }
}
</script>