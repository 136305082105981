<template src="./template.html"></template>

<script>
import qs from "qs";
export default {
  name: "Contact",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      comment: "",
      errors: [],
    }
  },
  methods: {
    ErrorCheck(type) {
      if (this.errors.filter(item => item.type == type).length > 0) {
        return this.errors.filter(item => item.type == type)[0].msg
      }
      else {
        return "empty"
      }
    },
    DataCheck() {
      this.errors = []
      let name_result = this.validName(this.name);
      let phone_result = this.validPhone(this.phone);
      let email_result = this.validEmail(this.email);
      let comment_result = this.validNotEmpty(this.comment);
      if (name_result != true) {
        this.errors.push({
          type: "name",
          msg: name_result
        })
      }

      if (phone_result != true) {
        this.errors.push({
          type: "phone",
          msg: phone_result
        })
      }

      if (email_result != true) {
        this.errors.push({
          type: "email",
          msg: email_result
        })
      }

      if (comment_result != true) {
        this.errors.push({
          type: "comment",
          msg: "請填入留言內容"
        })
      }

      if (this.errors.length <= 0) {
        this.SendData()
      }
    },
    SendData() {
      this.$emit("toggle-loading", true)
      let data = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        message: this.comment
      }
      this.axios.post('https://www.dashinplastics.com/mail/contact_me.php', qs.stringify(data)).then((response) => {
        if (response.data == "ok") {
          this.$emit("toggle-loading", false)
          this.$emit("toggle-dialog", [true, "信件已寄出，我們將會盡快回覆您！"])
        }
      })
    }
  }
}
</script>