<template src="./template.html"></template>

<script>
export default {
  name: "TimeLine",
  data() {
    return {
      timeline: [
        "樣品或圖樣",
        "確認事項",
        "進行估價",
        "進入排程",
        "模具設計製造",
        "試模",
        "產品檢測<p>(客戶確認)</p>",
        "修改<p>(二次試模)</p>",
        "產品確認",
        "導入量產",
        "包裝出貨 ",
      ]
    }
  },
  filters: {
    number(val) {
      if (val < 10) {
        return "0" + val;
      }
      else {
        return val;
      }
    }
  },
  methods: {
    delay(index) {
      if (window.innerWidth > 601) {
        let val = (index + 1) % 4
        if (val == 0) {
          val = 4
        }
        return val * 200
      } else {
        return 200
      }
    }
  }
}
</script>